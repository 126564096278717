<!--
 * @Author: jiang
 * @Date: 2021-06-11 11:02:01
 * @Description:
-->
<template>
  <div class="g-p-20">

    <el-table :data="list">
      <el-table-column
        label='ID'
        prop='id'
        width="80"
      ></el-table-column>
      <el-table-column
        label='标题'
        prop='title'
      ></el-table-column>
      <el-table-column
        label='来源'
        prop='author'
        width="180"
      ></el-table-column>
      <el-table-column
        label='发布日期'
        prop='created_at'
        width="180"
      ></el-table-column>
      <el-table-column
        label='操作'
        width="180"
        align="center"
      >
        <template slot-scope="{ row }">
          <c-btn-table
            :show="['info', (row.user && userId == row.user.id) ? 'update' : '', (row.user && userId == row.user.id) ? 'delete' : '']"
            @info="onView(row)"
            @update="onEdit(row)"
            @delete="onDelete(row)"
          ></c-btn-table>
        </template>
      </el-table-column>
    </el-table>

    <b-pagination
      :page="page"
      @change="fetchList"
    ></b-pagination>
  </div>
</template>

<script>
import { getUserNewsPage, deleteNews } from '@/api/news'
export default {
  data() {
    return {
      list: [],
      currentCategory: '',
      page: {
        current: 1,
        size: 15,
        total: 0,
      },
      searchData: {
        title: '',
        author: '',
        time: '',
      },
      infoVisible: false,
    }
  },
  computed: {
    userId() {
      return this.$store.getters['user/id']
    },
    deptId() {
      return this.$store.getters['user/deptId']
    },
  },
  created() {
    this.fetchList()
  },
  methods: {
    fetchList() {
      const parmas = {
        page: this.page.current,
      }
      getUserNewsPage(parmas).then(res => {
        this.list = res.data
        this.page.total = res.total
      })
    },
    onRefresh() {
      this.page.current = 1
      this.fetchList()
    },
    onReset() {
      this.$refs.form.resetFields()
      this.onRefresh()
    },
    onCategoryChange(data) {
      this.currentCategory = data.id
      this.fetchList()
    },
    onDelete(row) {
      this.$confirm('确定要删除当前项吗', '提示', { type: 'warning' })
        .then(() => {
          return deleteNews(row.id)
        })
        .then(() => {
          this.$message.success('删除成功')
          this.onRefresh()
        })
        .catch(() => {})
    },
    onAdd() {
      this.$router.push({ path: '/news/detail' })
    },
    onEdit(row) {
      this.$router.push({
        path: '/news/detail',
        query: { id: row.id },
      })
    },
    onView(row) {
      this.$router.push({ path: '/news/info', query: { id: row.id } })
    },
  },
}
</script>

<style lang="scss" scoped>
.news-container {
  display: flex;
  height: 100%;
  padding: 0 15px 15px;
}
</style>
